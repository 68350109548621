import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types';
import OrdersTable from './OrdersTable'
import _ from 'lodash'
import OrderUtilities from '../common/OrderUtilities';
import { performDefaultSortForOrders } from '../common/OrderUtilities';
import { convertDateToFirestoreTimestamp } from '../common/FirestoreUtilities';

RecentOrders.propTypes = {
  firebase: PropTypes.object.isRequired,
  db: PropTypes.object.isRequired,
  customerRefs: PropTypes.array.isRequired,
  materials: PropTypes.array.isRequired,
  purposes: PropTypes.array.isRequired,
  cities: PropTypes.array.isRequired,
  pricing: PropTypes.array.isRequired,
  drivers: PropTypes.array.isRequired,
  rowColorMap: PropTypes.object.isRequired
}

const columnsForTable = [
  'ref',
  'status',
  'customer',
  'requestedDeliveryDate',
  'billToAddress',
  'deliveryAddress',
  'city',
  'orderDate',
  'loads',
  'material',
  'purpose',
  'contact',
  'contactEmail',
  'po',
  'invoice',
  'subdivision',
  'price',
  'notes',
  'deliveryNotes',
  'readyDate',
  'dispatchedDate',
  'preloaded',
  'actualDeliveryDate',
  'readyToInvoiceDate',
  'driver',
  'area',
  'ticket',
  'pit',
  'cancelledDate',
  'heldDate',
]

export default function RecentOrders(props) {

  const orderUtilitiesRef = useRef(undefined)
  const deliveredOrdersQueryRef = useRef(undefined)
  const undeliveredOrdersQueryRef = useRef(undefined)
  const nullUndeliveredOrdersQueryRef = useRef(undefined)
  const cancelledOrdersQueryRef = useRef(undefined)
  const deliveredOrdersArray = useRef([])
  const undeliveredOrdersArray = useRef([])
  const nullUndeliveredOrdersArray = useRef([])
  const cancelledOrdersArray = useRef([])
  const combinedOrders = useRef([])
  const [ordersWithRefs, setOrdersWithRefs] = useState(undefined)

  const today = new Date()
  const startDate = new Date(today.setMonth(today.getMonth()-3))
  console.log(startDate)
  const earliestDate = useRef(convertDateToFirestoreTimestamp(props.firebase, startDate))
  console.log(earliestDate.current)

  useEffect(() => {
    deliveredOrdersQueryRef.current = props.db.collection('orders')
      .where('actualDeliveryDate', '>', earliestDate.current)
      .where('cancelled', '==', false)
    // deliveredOrdersQueryRef.current = query(props.db.collection('orders')
    //   ,where('actualDeliveryDate', '>', earliestDate.current)
    //   ,where('cancelled', '==', false))

    nullUndeliveredOrdersQueryRef.current = props.db.collection('orders')
      .where('actualDeliveryDate', '==', null)
      .where('cancelled', '==', false)
    // nullUndeliveredOrdersQueryRef.current = query(props.db.collection('orders')
    //   ,where('actualDeliveryDate', '==', null)
    //   ,where('cancelled', '==', false))

    undeliveredOrdersQueryRef.current = props.db.collection('orders')
      .where('actualDeliveryDate', '==', "")
      .where('cancelled', '==', false)
    // undeliveredOrdersQueryRef.current = query(props.db.collection('orders')
    //   ,where('actualDeliveryDate', '==', "")
    //   ,where('cancelled', '==', false))

    cancelledOrdersQueryRef.current = props.db.collection('orders')
      .where('cancelledDate', '>', earliestDate.current)
      .where('cancelled', '==', true)
    // cancelledOrdersQueryRef.current = query(props.db.collection('orders')
    //   ,where('cancelledDate', '>', earliestDate.current)
    //   ,where('cancelled', '==', true))

    const areaMap = _.keyBy(props.cities, (o) => { return o.area.trim() })
    const citiesMap = _.keyBy(props.cities, (o) => { return o.name.trim() })
    const subdivisionsMap = _.keyBy(props.subdivisions, (o) => { return o.trim() })
    const materialMap = _.keyBy(props.materials, (o) => { return o.name.trim() })
    const purposeMap = _.keyBy(props.purposes, (o) => { return o.name.trim() })
  
    orderUtilitiesRef.current = new OrderUtilities(
      props.firebase, 
      props.db,
      areaMap,
      props.customerRefs,
      citiesMap,
      subdivisionsMap,
      materialMap,
      purposeMap,
      props.pricing
    )

    const deliveredOrdersUnsubscribe = deliveredOrdersQueryRef.current
      .onSnapshot(querySnapshot => {
        deliveredOrdersArray.current = querySnapshot.docs.map(orderRef => {
          return orderUtilitiesRef.current.convertOrderForOrderTable(orderRef)
        })  
        combineOrders()
      })

    const undeliveredOrdersUnsubscribe = undeliveredOrdersQueryRef.current
      .onSnapshot(querySnapshot => {
        undeliveredOrdersArray.current = querySnapshot.docs.map(orderRef => {
          return orderUtilitiesRef.current.convertOrderForOrderTable(orderRef)
        })  
        combineOrders()
      })

    const nullUndeliveredOrdersUnsubscribe = nullUndeliveredOrdersQueryRef.current
      .onSnapshot(querySnapshot => {
        nullUndeliveredOrdersArray.current = querySnapshot.docs.map(orderRef => {
          return orderUtilitiesRef.current.convertOrderForOrderTable(orderRef)
        })  
        combineOrders()
      })

    const cancelledOrdersUnsubscribe = cancelledOrdersQueryRef.current
      .onSnapshot(querySnapshot => {
        cancelledOrdersArray.current = querySnapshot.docs.map(orderRef => {
          return orderUtilitiesRef.current.convertOrderForOrderTable(orderRef)
        })  
        combineOrders()
      })

    const combineOrders = () => {
      combinedOrders.current =  _.concat(deliveredOrdersArray.current, undeliveredOrdersArray.current, nullUndeliveredOrdersArray.current, cancelledOrdersArray.current);
      setOrdersWithRefs(performDefaultSortForOrders(combinedOrders.current))
    }

    const ordersUnsubscribe = () => {
      deliveredOrdersUnsubscribe()
      undeliveredOrdersUnsubscribe()
      nullUndeliveredOrdersUnsubscribe()
      cancelledOrdersUnsubscribe()
    }

    return () => {
      ordersUnsubscribe()
    }
  }, [props.firebase,
    props.db,
    props.query,
    props.customerRefs, 
    props.materials, 
    props.purposes, 
    props.cities, 
    props.pricing,
    props.actions,
    props.subdivisions]
  )

  return (
    <OrdersTable firebase={props.firebase}
      db={props.db}
      title={"Recent Orders"}
      columns={columnsForTable}
      actions={['cancel']}
      customerRefs={props.customerRefs}
      materials={props.materials}
      purposes={props.purposes}
      cities={props.cities}
      subdivisions={props.subdivisions}
      pricing={props.pricing}
      drivers={props.drivers}
      rowColorMap={props.rowColorMap}
      ordersWithRefs={ordersWithRefs}
      orderUtilities={orderUtilitiesRef.current}
      paging={true}
      pageSize={50}
    />
  )

}